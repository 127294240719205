const autocompleteSearchs = document.querySelectorAll(
  ".search-autocompeletion"
);

function createElement(tag, classes = [], textContent = "", attributes = {}) {
  const element = document.createElement(tag);

  // Add classes
  if (Array.isArray(classes)) {
    classes.forEach((className) => {
      element.classList.add(className);
    });
  }

  // Set text content
  if (textContent) {
    element.textContent = textContent;
  }

  // Set attributes
  for (const [key, value] of Object.entries(attributes)) {
    element.setAttribute(key, value);
  }

  return element;
}

function createResultsCard(
  sectionList,
  title,
  productImgSrc,
  flagImg,
  linkHref,
  docType,
  contentDescription
) {
  const card = createElement("div", ["results-card"]);

  const link = createElement("a", ["results-card-global-link"], "", {
    href: linkHref,
    target: "_blank",
  });
  card.appendChild(link);

  const pictureDiv = createElement("div", ["results-card-picture"]);
  const contentDiv = createElement("div", ["results-card-content"]);
  const titleDiv = createElement("div", ["results-card-title"]);
  const titleElement = createElement("h3", [], title);

  titleDiv.appendChild(titleElement);

  if (sectionList.className == "bloc-products-results-list-item-cards") {
    const productImg = createElement("img", [], "", {
      src: productImgSrc,
      alt: "picto",
    });

    pictureDiv.appendChild(productImg);
    card.appendChild(pictureDiv);
  }

  if (sectionList.className == "bloc-documents-results-list-item-cards") {
    const documentTag = createElement("div", ["results-card-tag"]);
    const siteTag = createElement("div", ["site-tag", "tag-transparent"]);
    const textTag = createElement("p", [], docType);
    const docFlag = createElement("div", ["results-card-lang"]);
    const flagImage = createElement("img", [], "", {
      src: flagImg[0],
      alt: "picto",
    });
    const downloadLinkWrap = createElement("div", ["results-card-download"]);
    const downloadLink = createElement("a", [], "", {
      href: linkHref,
      target: "_blank",
    });
    const downloadIcon = createElement("span", ["icon", "icon-download"]);

    siteTag.appendChild(textTag);
    documentTag.appendChild(siteTag);
    docFlag.appendChild(flagImage);
    documentTag.appendChild(docFlag);
    contentDiv.appendChild(documentTag);
    if (linkHref != "") {
      downloadLink.appendChild(downloadIcon);
      downloadLinkWrap.appendChild(downloadLink);
    }

    titleDiv.appendChild(downloadLinkWrap);
  }

  contentDiv.appendChild(titleDiv);

  if (sectionList.className == "bloc-contents-results-list-item-cards") {
    const contentText = createElement(
      "p",
      ["results-card-content-description", "mt-2"],
      contentDescription
    );

    contentDiv.appendChild(contentText);
  }

  card.appendChild(contentDiv);

  return card;
}

if (autocompleteSearchs.length) {
  autocompleteSearchs.forEach((autocompleteSearch) => {
    const inputSearch = autocompleteSearch;
    const clearInput = inputSearch.nextElementSibling;
    const apiRootUrl = inputSearch.dataset.rootApi;
    const autocompleteList = document.querySelector(".bloc-results-list");
    const productSectionList = autocompleteList.querySelector(
      ".bloc-products-results-list-item-cards"
    );
    const documentSectionList = autocompleteList.querySelector(
      ".bloc-documents-results-list-item-cards"
    );
    const supportSectionList = autocompleteList.querySelector(
      ".bloc-supports-results-list-item-cards"
    );
    const contentSectionList = autocompleteList.querySelector(
      ".bloc-contents-results-list-item-cards"
    );

    let lastRenderedData = {
      products: [],
      documents: [],
      supports: [],
      contents: [],
    };

    async function fetchData(searchedValue) {
      try {
        const response = await fetch(`${apiRootUrl}?q=${searchedValue}`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
        return {
          products: { items: [] },
          documents: { items: [] },
          supports: { items: [] },
          contents: { items: [] },
        };
      }
    }

    function hasDataChanged(newData) {
      const currentProducts = JSON.stringify(newData?.products?.items);
      const currentDocuments = JSON.stringify(newData?.documents?.items);
      const currentSupports = JSON.stringify(newData?.supports?.items);
      const currentContents = JSON.stringify(newData?.contents?.items);

      const lastProducts = JSON.stringify(lastRenderedData.products);
      const lastDocuments = JSON.stringify(lastRenderedData.documents);
      const lastSupports = JSON.stringify(lastRenderedData.supports);
      const lastContents = JSON.stringify(lastRenderedData.contents);

      return (
        currentProducts !== lastProducts ||
        currentDocuments !== lastDocuments ||
        currentSupports !== lastSupports ||
        currentContents !== lastContents
      );
    }

    function renderResults(
      searchedValue,
      sectionLists,
      filteredItems,
      totalCount
    ) {
      // set total count
      document.querySelector(".list-all-result .results-count").innerHTML =
        totalCount;

      const allResultsLinks = document.querySelectorAll(
        ".list-all-result .link"
      );

      if (allResultsLinks) {
        allResultsLinks.forEach(function (allResultsLink) {
          allResultsLink.setAttribute(
            "href",
            allResultsLink.dataset.link + searchedValue
          );
        });
      }

      sectionLists.forEach((sectionList, index) => {
        if (sectionList) {
          const items = filteredItems[index];
          const allTypeResultBaseLink = sectionList
            .closest(".bloc-results-list-item")
            .querySelector(".link");

          if (allTypeResultBaseLink) {
            const allResultsLink =
              allTypeResultBaseLink.dataset.link + searchedValue;
            allTypeResultBaseLink.setAttribute("href", allResultsLink);
          }

          // Clear previous items
          sectionList.innerHTML = "";

          if (!items || items.length === 0) {
            sectionList.parentNode.classList.add("d-none");
            return;
          } else {
            sectionList.parentNode.classList.remove("d-none");
            items.forEach((item) => {
              const newCard = createResultsCard(
                sectionList,
                item.name,
                item.image_url,
                item.flags,
                item.link,
                item.document_type,
                item.description
              );
              sectionList.appendChild(newCard);
            });
          }
        }
      });

      // Update last rendered data
      lastRenderedData = {
        products: filteredItems[0],
        documents: filteredItems[1],
        supports: filteredItems[2],
      };
    }

    inputSearch.addEventListener("input", async () => {
      const value = inputSearch.value;

      if (clearInput) {
        clearInput.classList.remove("d-none");
      }

      if (!value) {
        autocompleteList.classList.add("d-none");
        clearInput.classList.add("d-none");
        lastRenderedData = {
          products: [],
          documents: [],
          supports: [],
          contents: [],
        };
        return;
      }

      // Fetch data and filter based on input
      const data = await fetchData(value);

      if (data.totalCount == 0) {
        autocompleteList.classList.add("d-none");
        lastRenderedData = {
          products: [],
          documents: [],
          supports: [],
          contents: [],
        };
        return;
      }

      // Check if the new data is different from the last rendered data
      if (hasDataChanged(data)) {
        // Render results for products, documents, and supports
        renderResults(
          value,
          [
            productSectionList,
            documentSectionList,
            supportSectionList,
            contentSectionList,
          ],
          [
            data?.products?.items,
            data?.documents?.items,
            data?.supports?.items,
            data?.contents?.items,
          ],
          data.totalCount
        );

        autocompleteList.classList.remove("d-none");
      }
    });

    //close mobile search

    const closeBtn = document.querySelector(
      ".bloc-results-list-mobile .btn-close"
    );

    if (closeBtn) {
      closeBtn.addEventListener("click", function () {
        autocompleteList.classList.add("d-none");
        clearInput.classList.add("d-none");
        inputSearch.value = "";
        lastRenderedData = {
          products: [],
          documents: [],
          supports: [],
          contents: [],
        };
      });
    }

    // clear input
    if (clearInput) {
      clearInput.addEventListener("click", () => {
        inputSearch.value = "";
        autocompleteList.classList.add("d-none");
        lastRenderedData = {
          products: [],
          documents: [],
          supports: [],
          contents: [],
        };
        clearInput.classList.add("d-none");
      });
    }
  });
}

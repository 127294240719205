const sidebarToggle = document.querySelectorAll(".js-sidebar-toggle");
const body = document.querySelector("body");
const sidebar = document.getElementById("js-dashboard-sidebar");

if (sidebarToggle) {
  sidebarToggle.forEach((element) => {
    element.addEventListener("click", function () {
      sidebar.classList.toggle("opened");
      body.classList.toggle("overflow-hidden");
    });
  });
}

const productSheetSlider = document.querySelector(".bloc-product-sheet-slider");

if (productSheetSlider) {
  const slideItems = productSheetSlider.querySelectorAll(".swiper-slide");
  if (slideItems.length >= 2) {
    import("swiper/bundle").then(({ Swiper }) => {
      const swiperWrapper = productSheetSlider.querySelector(
        ".bloc-product-sheet-slider-content"
      );

      const nextButton = productSheetSlider.querySelector(
        ".swiper-button-next"
      );
      const prevButton = productSheetSlider.querySelector(
        ".swiper-button-prev"
      );

      new Swiper(swiperWrapper, {
        autoplay: false,
        loop: false,
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
      });
    });
  }
}

const body = document.querySelector("body");
const header = document.getElementById("ca-header");
const megaMenuItems = document.querySelectorAll(
  ".mega-menu-item.has-mega-menu"
);
const subMegaMenuItems = document.querySelectorAll(
  ".sub-mega-menu-item.has-mega-menu"
);
const level2Wrapper = document.querySelectorAll(
  ".level-2 > .ca-mega-menu-nav-wrap"
);
const level2Header = document.querySelectorAll(
  ".level-2 > .ca-mega-menu-header"
);
const closeMenus = document.querySelectorAll(".close-menus");
const goBacks = document.querySelectorAll(".go-back");
const headerContainer = document.querySelector(".container-xxxl");

/*
 * Handle first mega menu level :D
 */

if (megaMenuItems.length) {
  megaMenuItems.forEach(function (navigationMegaMenuItem) {
    const megaMenuToggleLink = navigationMegaMenuItem.querySelector("a");

    if (megaMenuToggleLink) {
      megaMenuToggleLink.addEventListener("click", function (e) {
        e.preventDefault();

        const currentelement = e.target.parentElement;

        if (!currentelement.classList.contains("mega-menu-item-opened")) {
          megaMenuItems.forEach(function (menuItem) {
            menuItem.classList.remove("mega-menu-item-opened");
          });
        }

        currentelement.classList.toggle("mega-menu-item-opened");

        navigationMegaMenuItem.querySelector(".level-1").style.marginLeft =
          `${headerContainer.offsetLeft}px`;
      });
    }
  });
}

/*
 * Handle inner mega menu levels :D
 */

if (subMegaMenuItems.length) {
  subMegaMenuItems.forEach(function (navigationMegaMenuItem) {
    const megaMenuToggleLink = navigationMegaMenuItem.querySelector("a");

    if (megaMenuToggleLink) {
      megaMenuToggleLink.addEventListener("click", function (e) {
        e.preventDefault();

        const currentelement = e.target.parentElement;

        if (!currentelement.classList.contains("mega-menu-item-opened")) {
          subMegaMenuItems.forEach(function (menuItem) {
            menuItem.classList.remove("mega-menu-item-opened");
          });
        }

        currentelement.classList.toggle("mega-menu-item-opened");

        if (level2Header.length) {
          level2Header.forEach(function (header) {
            header.style.width =
              window.innerWidth - (510 + headerContainer.offsetLeft) + "px";
          });
        }

        // TODO only in mobile
        if (level2Wrapper.length) {
          level2Wrapper.forEach(function (wrapper) {
            const headerMegaMenuHeight = wrapper.parentNode.querySelector(
              ".ca-mega-menu-header"
            ).offsetHeight;
            wrapper.style.width =
              window.innerWidth - (510 + headerContainer.offsetLeft) + "px";

            wrapper.style.setProperty(
              "height",
              "calc(100% - " + headerMegaMenuHeight + "px)"
            );
          });
        }
      });
    }
  });
}

/*
 * Closing all menus levels :p
 */

if (closeMenus.length) {
  closeMenus.forEach(function (closeMenu) {
    closeMenu.addEventListener("click", function () {
      header.classList.remove("menu-open");
      body.classList.remove("overflow-hidden", "mega-menu-open");

      megaMenuItems.forEach(function (navigationMegaMenuItem) {
        navigationMegaMenuItem.classList.remove("mega-menu-item-opened");
      });

      subMegaMenuItems.forEach(function (navigationSubMegaMenuItem) {
        navigationSubMegaMenuItem.classList.remove("mega-menu-item-opened");
      });
    });
  });
}

/*
 * Go back to the previous level :v
 */

if (goBacks.length) {
  goBacks.forEach(function (goBack) {
    goBack.addEventListener("click", function () {
      const megaMenuItem = goBack.closest(".has-mega-menu");

      megaMenuItem.classList.remove("mega-menu-item-opened");

      if (megaMenuItem.querySelector(".level-1")) {
        body.classList.remove("mega-menu-open");
      }
    });
  });
}

/*
 * Align mega menu with header container
 */

window.addEventListener("resize", () => {
  megaMenuItems.forEach(function (navigationMegaMenuItem) {
    navigationMegaMenuItem.querySelector(".level-1").style.marginLeft =
      `${headerContainer.offsetLeft}px`;
  });
});

if (window.matchMedia("(max-width: 767px)").matches) {
  const testimonialsBlocs = document.querySelectorAll(".bloc-testimonials");

  if (testimonialsBlocs.length) {
    import("swiper/bundle").then(({ Swiper }) => {
      testimonialsBlocs.forEach(function (testimonialsBloc) {
        const swiperWrapper = testimonialsBloc.querySelector(
          ".bloc-testimonials-wrapper"
        );
        const nextButton = testimonialsBloc.querySelector(
          ".swiper-button-next"
        );
        const prevButton = testimonialsBloc.querySelector(
          ".swiper-button-prev"
        );

        new Swiper(swiperWrapper, {
          autoplay: false,
          loop: false,
          breakpoints: {
            280: {
              slidesPerView: 1.135,
              spaceBetween: 30,
            },
            380: {
              slidesPerView: 1.135,
              spaceBetween: 30,
            },
            480: {
              slidesPerView: 1.45,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1.55,
              spaceBetween: 40,
            },
          },
          navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
          },
        });
      });
    });
  }
}

const stickyAncr = document.querySelectorAll(".sticky-ancr");
const stickyNav = document.querySelector(".sticky-nav");

if (stickyAncr.length) {
  stickyAncr.forEach(function (navAncr) {
    const stickyAncrItems = navAncr.querySelectorAll(".sticky-ancr-item");

    if (stickyAncrItems.length) {
      stickyAncrItems.forEach(function (item) {
        item.addEventListener("click", function (e) {
          const activeItem = item.dataset.target;
          const targetItem = item.getAttribute("href");

          if (targetItem && stickyNav) {
            e.preventDefault();

            const scrollToPosition =
              document.querySelector(targetItem).offsetTop - 143;

            window.scrollTo({
              top: scrollToPosition,
              behavior: "smooth",
            });
          }

          stickyAncr.forEach(function (disableItems) {
            const disableAncrItems =
              disableItems.querySelectorAll(".sticky-ancr-item");

            if (disableAncrItems.length) {
              disableAncrItems.forEach(function (item) {
                item.classList.remove("active");
              });
            }
          });

          if (activeItem) {
            document
              .querySelectorAll(`[data-target="${activeItem}"]`)
              .forEach(function (activeAncr) {
                activeAncr.classList.add("active");
              });
          }
        });
      });
    }
  });
}

/* bootstrap modules */
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tab";

/* css global entries */
import "../scss/index.scss";

/* CA module */
import "./header";
import "./header-mega-menu";
import "./banner-slider";
import "./input-date";
import "./form-validation";
import "./certification-slider";
import "./video";
import "./block-form";
import "./solutions-slider";
import "./testimonials-slider";
import "./sticky-nav";
import "./lazy-iframe-modal";
import "./input-autocompletion";
import "./products-slider";
import "./product-sheet-slider";
import "./sticky-ancr";
import "./dashboard-toggle";
import "./search-autocompletion";

const bannerSlider = document.querySelectorAll(".bloc-banner-slider");

if (bannerSlider.length) {
  bannerSlider.forEach(function (item) {
    const slideItems = item.querySelectorAll(".swiper-slide");

    if (slideItems.length >= 2) {
      import("swiper/bundle").then(({ Swiper }) => {
        const sliderNavigation = item.querySelector(
          ".bloc-banner-slider-navigation"
        );
        const swiperWrapper = item.querySelector(
          ".bloc-banner-slider-container"
        );
        const swiper = new Swiper(swiperWrapper, {
          autoplay: true,
          loop: true,
        });

        if (sliderNavigation) {
          const playIcon = sliderNavigation.querySelector(".icon");

          sliderNavigation.addEventListener(
            "click",
            function () {
              if (swiper.autoplay.running) {
                swiper.autoplay.stop();
                playIcon.classList.remove("icon-pause");
                playIcon.classList.add("icon-play");
              } else {
                swiper.autoplay.start();
                playIcon.classList.remove("icon-play");
                playIcon.classList.add("icon-pause");
              }
            },
            { passive: true }
          );
        }
      });
    }
  });
}

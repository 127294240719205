const lazyIframeModals = document.querySelectorAll(".lazy-iframe-modal");

if (lazyIframeModals.length) {
  lazyIframeModals.forEach((lazyIframeModal) => {
    const iframeDataSrc = lazyIframeModal.dataset.modalSrc;

    if (iframeDataSrc) {
      const iframe = document.createElement("iframe");
      iframe.src = iframeDataSrc;

      lazyIframeModal.addEventListener("show.bs.modal", function () {
        lazyIframeModal.querySelector(".modal-body").appendChild(iframe);
      });
    }
  });
}

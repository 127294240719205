if (window.matchMedia("(max-width: 992px)").matches) {
  const solutionsBlocs = document.querySelectorAll(".bloc-solutions");

  if (solutionsBlocs.length) {
    import("swiper/bundle").then(({ Swiper }) => {
      solutionsBlocs.forEach(function (solutionsBloc) {
        const swiperWrapper = solutionsBloc.querySelector(
          ".bloc-solutions-wrapper"
        );
        const nextButton = solutionsBloc.querySelector(".swiper-button-next");
        const prevButton = solutionsBloc.querySelector(".swiper-button-prev");

        new Swiper(swiperWrapper, {
          autoplay: false,
          loop: false,
          spaceBetween: 20,
          breakpoints: {
            300: {
              slidesPerView: 1.2,
            },
            380: {
              slidesPerView: 1.33,
            },
            480: {
              slidesPerView: 1.55,
            },
            720: {
              slidesPerView: 2,
            },
          },
          navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
          },
        });
      });
    });
  }
}

const certificationBlocs = document.querySelectorAll(".bloc-certification");

if (certificationBlocs.length) {
  import("swiper/bundle").then(({ Swiper }) => {
    certificationBlocs.forEach(function (certificationBloc) {
      const swiperWrapper = certificationBloc.querySelector(
        ".bloc-certification-images-container"
      );
      const nextButton = certificationBloc.querySelector(".swiper-button-next");
      const prevButton = certificationBloc.querySelector(".swiper-button-prev");

      new Swiper(swiperWrapper, {
        autoplay: false,
        loop: false,
        spaceBetween: 0,
        breakpoints: {
          320: {
            slidesPerView: 1.6,
          },
          390: {
            slidesPerView: 1.8,
          },
          430: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 2.3,
          },
          590: {
            slidesPerView: 2.4,
          },
          768: {
            slidesPerView: 3.1,
          },
          900: {
            slidesPerView: 2.95,
          },
          960: {
            slidesPerView: 2.85,
          },
          992: {
            slidesPerView: 2.5,
          },
          1200: {
            slidesPerView: 2.8,
          },
          1300: {
            slidesPerView: 3.3,
          },
          1440: {
            slidesPerView: 3.5,
          },
          1500: {
            slidesPerView: 3.7,
          },
          1750: {
            slidesPerView: 4.2,
          },
        },
        navigation: {
          nextEl: nextButton,
          prevEl: prevButton,
        },
      });
    });
  });
}

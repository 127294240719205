const mobileMenuToggle = document.getElementById("js-mobile-menu-toggle");
const header = document.getElementById("ca-header");
const body = document.querySelector("body");

if (mobileMenuToggle) {
  mobileMenuToggle.addEventListener(
    "click",
    function () {
      if (document.documentElement.scrollTop !== 0) {
        window.scrollTo(0, 0);
      }
      header.classList.toggle("menu-open");
      body.classList.toggle("overflow-hidden");
    },
    { passive: true }
  );
}

const form = document.querySelector(".block-form-builder form");

function formatDate() {
  const dateInput = document.querySelector(".date-field");

  // Parse the date in "dd/mm/yyyy" format
  if (dateInput) {
    const parts = dateInput.value.split("/");
    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];

      // Create a new Date object in "mm/dd/yyyy" format
      const parsedDate = new Date(`${year}-${month}-${day}`);

      if (!isNaN(parsedDate)) {
        const monthFormatted = String(parsedDate.getMonth() + 1).padStart(
          2,
          "0"
        );
        const dayFormatted = String(parsedDate.getDate()).padStart(2, "0");
        const formattedDate = `${monthFormatted}/${dayFormatted}/${year}`;
        dateInput.value = formattedDate;
      }
    }
  }
}

if (form) {
  import("pristinejs/dist/pristine").then((module) => {
    const Pristine = module.default;
    const pristine = new Pristine(form);

    form.addEventListener("submit", function (e) {
      e.preventDefault();
      formatDate();
      // check if the form is valid
      pristine.validate();

      if (pristine.validate()) {
        form.submit();
      } else {
        const hasDanger = document.querySelector(".has-danger");

        if (hasDanger) {
          window.scrollTo({
            top: document.querySelector(".has-danger").offsetTop,
            behavior: "smooth",
          });
        }
      }
    });
  });

  //bs custom file input

  import("bs-custom-file-input").then((module) => {
    const bsCustomFileInput = module.default;
    bsCustomFileInput.init();
  });
}

const blocVideos = document.querySelectorAll(".bloc-video");

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function parseCookieValue(cookieValue) {
  let pairs = cookieValue.split("!");
  let result = {};
  pairs.forEach((pair) => {
    if (pair) {
      let [key, value] = pair.split("=");
      result[key] = value;
    }
  });
  return result;
}

function initPlayer(element, videoId) {
  import("plyr").then(({ default: Plyr }) => {
    const player = new Plyr(element, {
      videoId: videoId,
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "captions",
        "settings",
        "pip",
        "airplay",
        "fullscreen",
      ],
      clickToPlay: true,
      youtube: { noCookie: true },
    });

    player.on("ready", function () {
      player.play();
    });

    const selectModals = document.querySelectorAll(".modal");

    if (selectModals.length) {
      selectModals.forEach(function (modal) {
        modal.addEventListener("hidden.bs.modal", () => {
          player.pause();
        });
      });
    }
  });
}

if (blocVideos.length) {
  blocVideos.forEach(function (blocVideo) {
    const videoId = blocVideo.getAttribute("data-plyr-embed-id");

    if (iOS()) {
      const imgIOS = blocVideo.getAttribute("data-img-ios");
      const srcImg = blocVideo.querySelector("img");

      if (imgIOS !== undefined && imgIOS !== null && srcImg) {
        srcImg.setAttribute("src", imgIOS);
      }
    }

    try {
      const cookieValue = getCookie("cookie_manager");

      if (cookieValue) {
        const parsedValues = parseCookieValue(cookieValue);

        if (parsedValues.youtube !== "true") {
          blocVideo.classList.add("tarteaucitron-is-denied");
        } else {
          blocVideo.classList.remove("tarteaucitron-is-denied");

          blocVideo.addEventListener("click", function () {
            initPlayer(this, videoId);
          });
        }
      } else {
        blocVideo.classList.add("tarteaucitron-is-denied");
      }
    } catch (e) {
      blocVideo.classList.add("tarteaucitron-is-denied");
      console.log(e);
    }
  });
}

const $dateFields = document.querySelectorAll(".date-field");

if ($dateFields.length) {
  $dateFields.forEach(function (dateField) {
    const localePaths = {
      fr: require("vanillajs-datepicker/locales/fr"),
      en: require("vanillajs-datepicker/locales/en-AU"),
      // Add more locale paths as needed
    };

    const websiteLang =
      document.documentElement.lang.trim().toLowerCase() || "fr";
    const localeModule = localePaths[websiteLang];
    const dateFormatUI = dateField
      .closest(".date-field__wrapper")
      .querySelector(".date-value-ui");

    // add date format
    const dateFormat = dateField
      .closest(".form-group")
      .querySelector(".date-format");

    if (dateFormat) {
      dateFormat.innerHTML =
        websiteLang == "en"
          ? "mm/dd/yyyy"
          : localeModule.default[websiteLang].format;
    }

    import("vanillajs-datepicker").then(({ Datepicker }) => {
      Object.assign(Datepicker.locales, localeModule.default);
      new Datepicker(dateField, {
        autohide: true,
        locale: localeModule.default,
        language: websiteLang,
      });
      if (dateFormatUI) {
        dateFormatUI.innerHTML = dateField.value;
        // Listen for the changeDate event
        dateField.addEventListener("changeDate", function () {
          dateFormatUI.innerHTML = dateField.value;
        });
      }
    });
  });
}

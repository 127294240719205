const autocompleteInputs = document.querySelectorAll(".input-autocompeletion");

if (autocompleteInputs.length) {
  autocompleteInputs.forEach(function (autocompleteInput) {
    const autocompeletionUrl = autocompleteInput.dataset.urlAutocompeletion;

    if (autocompeletionUrl) {
      import("awesomplete").then((module) => {
        const Awesomplete = module.default;
        const awesomplete = new Awesomplete(autocompleteInput, {
          minChars: 3,
          maxItems: 10,
          item: (text) => {
            const query = autocompleteInput.value;
            const regex = new RegExp(`(${query})`, "i"); // Create a regex to match the query
            const highlightedText = text.replace(regex, "<span>$1</span>"); // Wrap matches with <strong>
            const listItem = Awesomplete.$.create("li", {
              innerHTML: highlightedText,
            });
            return listItem;
          },
        });

        // Add the keyup event listener to update the list dynamically
        autocompleteInput.addEventListener("keyup", function () {
          const query = autocompleteInput.value;

          if (query.length >= 3) {
            const ajax = new XMLHttpRequest();
            ajax.open("GET", `${autocompeletionUrl}?q=${query}`, true); // Assuming the API accepts a query param
            ajax.onload = function () {
              const list = JSON.parse(ajax.responseText).map(function (res) {
                return res.nameProduct;
              });
              awesomplete.list = list; // Update the list with the new data
            };
            ajax.send();
          }
        });

        // Awesomplete event listeners
        awesomplete.input.addEventListener("awesomplete-open", function () {
          autocompleteInput.classList.add("awesomplete-list-open");
        });
        awesomplete.input.addEventListener("awesomplete-close", function () {
          autocompleteInput.classList.remove("awesomplete-list-open");
        });
      });
    }
  });
}

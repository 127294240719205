if (window.matchMedia("(max-width: 992px)").matches) {
  const productsBlocContainer = document.querySelectorAll(
    ".bloc-highlighting-products"
  );

  if (productsBlocContainer.length) {
    import("swiper/bundle").then(({ Swiper }) => {
      productsBlocContainer.forEach(function (productsBloc) {
        const swiperWrapper = productsBloc.querySelector(
          ".bloc-highlighting-products-content"
        );
        const nextButton = productsBloc.querySelector(".swiper-button-next");
        const prevButton = productsBloc.querySelector(".swiper-button-prev");

        new Swiper(swiperWrapper, {
          autoplay: false,
          loop: false,
          breakpoints: {
            280: {
              slidesPerView: 1.135,
              spaceBetween: 30,
            },
            380: {
              slidesPerView: 1.25,
              spaceBetween: 30,
            },
            480: {
              slidesPerView: 1.45,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1.55,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 2.55,
              spaceBetween: 40,
            },
          },
          navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
          },
        });
      });
    });
  }
}

// sticky candidate bar

const stickyCandidateBar = document.querySelector(".sticky-cta-candidate");
const megaBanner = document.querySelector(".bloc-mega-banner");

if (stickyCandidateBar && megaBanner) {
  window.onscroll = function () {
    const sticky = megaBanner.offsetTop + megaBanner.offsetHeight;

    if (window.scrollY > sticky) {
      stickyCandidateBar.classList.remove("d-none");
    } else {
      stickyCandidateBar.classList.add("d-none");
    }
  };
}

// sticky navbar

const stickyNav = document.querySelector(".sticky-nav");
const bannerSlider = document.querySelector(".banner-slider");

if (stickyNav && bannerSlider) {
  window.onscroll = function () {
    const sticky = bannerSlider.offsetTop + bannerSlider.offsetHeight;

    if (window.scrollY > sticky) {
      stickyNav.classList.remove("d-none");
    } else {
      stickyNav.classList.add("d-none");
    }
  };
}
